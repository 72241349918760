<template>
    <div>
        <v-card>
            <v-container>
                <div class="mb-2">
                    <h2 class="pa-3">Histórico de Status</h2>
                </div>
                <v-card flat solo outlined>
                    <div
                        class="ma-2 d-block"
                        v-for="item in historicotitulo"
                        v-bind:key="item.id_historico"
                        data-cy="listagemHistoricoTitulo"
                    >
                        <b>
                            {{
                                item.dt_arquivo_movimento
                                    ? dtMovimentoFormat(
                                          item.dt_arquivo_movimento
                                      )
                                    : item.dt_historico
                            }}
                        </b>
                        -
                        {{ historicoStatus(item) }}
                        <v-tooltip
                            v-show="item.user_id"
                            top
                            width="300"
                            max-width="350"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    class="mx-2"
                                    elevation="0"
                                    v-bind="attrs"
                                    v-on="on"
                                    v-show="item.user_id"
                                    x-small
                                >
                                    Detalhes
                                    <v-icon x-small
                                        >mdi-information-outline</v-icon
                                    >
                                </v-btn>
                            </template>
                            <span>
                                Ação solicitada pelo usuário:
                                {{ item.ds_login }}
                            </span>
                        </v-tooltip>
                        <!-- <v-card-text v-show="item.user_id"> Ação feita pelo usuário: {{ item.user_id }}</v-card-text> -->
                        <v-icon
                            v-show="item.mostrarDebugButton"
                            @click="
                                modificaestado(historicotitulo.indexOf(item))
                            "
                            small
                            >{{ item.botao_mostra_detalhes }}</v-icon
                        >

                        <v-container v-show="item.vshow">
                            <div>
                                {{ protocoloHistorico(item) }}
                            </div>
                            <div>
                                {{ historicoDebug(item) }}
                            </div>
                        </v-container>
                    </div>
                </v-card>
            </v-container>
        </v-card>
    </div>
</template>

<script>
import { formatarDataHora } from '@/helpers/Utilitarios';
import moment from 'moment';

export default {
    name: 'HistoricoTitulo',
    props: {
        historico_titulo: {
            type: Array
        },
        showActionSalvar: Boolean
    },

    data: () => ({
        mostrarDebug: false,
        historicotitulo: []
    }),
    mounted() {
        this.ordenarByDataOcorrencia();
        this.montaListaHistoricoTitulo();
    },
    watch: {
        historico_titulo: function() {
            this.montaListaHistoricoTitulo();
        }
    },
    methods: {
        formatarDataHora,
        ordenarByDataOcorrencia() {
            if (this.historico_titulo && this.historico_titulo.length > 0) {
                this.historico_titulo.forEach(ht => {
                    ht.dt_ordenacao = ht.dt_arquivo_movimento
                        ? ht.dt_arquivo_movimento
                        : this.formatarDataHistorico(ht.dt_historico);
                    ht.dt_ordenacao = new Date(ht.dt_ordenacao);
                });
                this.historico_titulo.sort((a, b) => {
                    return b.dt_ordenacao - a.dt_ordenacao;
                });
            }
        },
        formatarDataHistorico(dataHistorico) {
            const [data, hora] = dataHistorico.split(' ');
            const [dia, mes, ano] = data.split('/');
            const dataFormatada = `${ano}-${mes}-${dia} ` + hora;

            return dataFormatada;
        },
        dtMovimentoFormat(data) {
            return moment(data).format('DD/MM/YYYY HH:mm:ss');
        },
        historicoFormat(item) {
            if (!item) return null;
            const [data] = item.split(' ');
            return data;
        },
        validarDescricaoHistoricoDebug(descricao) {
            if (
                descricao != null &&
                typeof descricao.split('--')[1] !== 'undefined'
            ) {
                return true;
            }
            return false;
        },
        montaListaHistoricoTitulo() {
            this.historicotitulo = this.historico_titulo;
            for (let titulos in this.historicotitulo) {
                this.historicotitulo[titulos].vshow = false;
                this.historicotitulo[titulos].botao_mostra_detalhes =
                    'keyboard_arrow_down';
                if (
                    this.validarDescricaoHistoricoDebug(
                        this.historicotitulo[titulos].DESCRICAO
                    ) ||
                    this.historicotitulo[titulos].apontamento_nu_protocolo !=
                        null
                ) {
                    this.historicotitulo[titulos].vshow = true;
                } else {
                    this.historicotitulo[titulos].vshow = false;
                }
            }
        },
        historicoStatus(item) {
            const descricao = item.DESCRICAO;
            if (descricao != null) {
                return descricao.split('--')[0];
            }
            return null;
        },

        dadosHistorico(item) {
            if (item.dt_ocorrencia == null) {
                return item.dt_historico;
            } else return item.dt_ocorrencia;
        },
        protocoloHistorico(item) {
            let mensagemProtocolo = '';
            if (item.apontamento_nu_protocolo) {
                mensagemProtocolo += `Nº Protocolo do Histórico: ${item.apontamento_nu_protocolo}`;
            }
            return mensagemProtocolo;
        },
        historicoDebug(item) {
            const descricao = item.DESCRICAO;
            if (this.validarDescricaoHistoricoDebug(descricao)) {
                return descricao.split('--')[1];
            }
            return null;
        },
        modificaestado(index) {
            this.historicotitulo[index].vshow = !this.historicotitulo[index]
                .vshow;
            if (this.historicotitulo[index].vshow == true) {
                this.historicotitulo[index].botao_mostra_detalhes =
                    'keyboard_arrow_down';
            } else {
                this.historicotitulo[index].botao_mostra_detalhes =
                    'keyboard_arrow_right';
            }
            this.$forceUpdate();
        }
    }
};
</script>
